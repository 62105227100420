
import React, { useState, useEffect, useContext } from 'react';
import '../Styles/App.css';
import {Trans, useTranslation} from "react-i18next";
import {ErrorPage, GenerateError, NinjaLoader, secondsToDays} from "./Tools";
import Api from "./api";
import {useHistory} from "react-router-dom";
import {BorrowerContext} from "./App";
import {Modal} from "react-bootstrap";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

function Loan(){
    const [loan_data, setLoanData] = useState({});
    const borrower_context_data = useContext(BorrowerContext);
    const [isLoading, setIsLoading] = useState(true);
    const [error_resp, setError] = useState(null);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        borrower_context_data.setActiveStep(1);
    },[]);
    useEffect(() => {
        function getLoanData() {
            setIsLoading(true);
            Api.defaults.headers.post['Ninja-Lang'] = i18n.language;
            Api.post('/loan/get', {"params":{"id":borrower_context_data.loan.id}})
                .then(
                    (result) => {
                        let response = result.data;
                        if(response.code === 0){
                            setLoanData(response.data);

                            let loan = borrower_context_data.loan;
                            loan.stage = response.data.loan.stage;
                            borrower_context_data.fillLoan(loan);

                        } else {
                            setError(GenerateError(response.errors[0].code));
                        }
                        setIsLoading(false);

                    },

                    (error) => {
                        setIsLoading(false);
                        setError(GenerateError());
                    }
                )
        }
        getLoanData();
    }, [i18n.language]);
    if (error_resp)
    {
        return <ErrorPage {...error_resp}/>
    }
    else
    {
        if(isLoading) {
            return (<NinjaLoader />);
        } else {
            return (<LoanView loan = {loan_data.loan}/>);
        }

    }

}

export default Loan;

export function LoanView(props){
    const [is_disabled, setIsDisabled] = useState(false);
    const borrower_context_data = useContext(BorrowerContext);

    useEffect(() => {
        if(borrower_context_data.loan.stage === 12) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    },[borrower_context_data]);

    let history = useHistory();
    const { t, i18n } = useTranslation();

    function confirmLoan() {
        if(props.loan.is_suggestions_filled === false) {
            history.push('/suggestions');
        } else {
            history.push('/distributing');
        }
    }

    return (
        <div>
            <div>
                <h1 className="centered_text">{t('loan_title')}</h1>
            </div>
            <div className="centered_text top-margin-20">
                <Trans
                    i18nKey="loan_descr"
                    values={{
                        partner_name: props.loan.organization.name,
                    }}
                    components={[<strong />]}
                />
            </div>
            <div className="loan-content top-margin-20">
                <div className="loan-heading">
                    <div className="loan-heading-col">
                        <div className="heading-col-label">{t('loan_amount')}</div>
                        <div className="heading-col-text">{props.loan.amount}&nbsp;{process.env.REACT_APP_CURRENCY}</div>
                    </div>
                    <div className="loan-heading-col">
                        <div className="heading-col-label">{t('loan_term')}</div>
                        <div className="heading-col-text">{secondsToDays(props.loan.term)}&nbsp;{t('days_measure_unit')}</div>
                    </div>
                </div>
                <LoanRequisites loan={props.loan}/>
            </div>
            {is_disabled &&
            <div className="top-margin">
                <p className="consent-hint">
                <ErrorOutlineIcon className="tools-icon"/> {t("processing_stopped_tip")}
                </p>
            </div>
            }
            <div className="flex-center-row-container">
                <div className="top-margin">
                    <button className="nl-button" disabled={is_disabled} onClick={() => confirmLoan()}>{t('loan_confirm_button')}</button>
                </div>
            </div>

            <HelpMyLoan loan_id={props.loan.id}/>
        </div>
    )
}

export function LoanRequisites(props) {
    const { t, i18n } = useTranslation();

    const [see_full, setSeeFull] = useState(false);
    function seeFullToggle() {
        setSeeFull(!see_full);
    }

    const full_requisites = Object.keys(props.loan.requisites.regular).map((key) => {

        return <div className="loan-requisite-col" key={key}>
            <div className="loan-requisite-title">
                {props.loan.requisites.regular[key].title}
            </div>
            <div className="loan-requisite-data">
                {props.loan.requisites.regular[key].value}
            </div>
        </div>


    });
    const front_requisites = Object.keys(props.loan.requisites.front).map((key) => {

        return <div className="loan-requisite-col" key={key}>
            <div className="loan-requisite-title">
                {props.loan.requisites.front[key].title}
            </div>
            <div className="loan-requisite-data">
                {props.loan.requisites.front[key].value}
            </div>
        </div>


    });

    return (
        <>
            <div className="loan-requisites">
                <div className="loan-requisite-row">
                    {front_requisites}
                </div>

                {see_full &&
                <div className="loan-requisite-row">
                    {full_requisites}
                </div>
                }
            </div>
            <div className="flex-center-row-container top-margin-20">
                <div className="tools-icon">
                    <a className="nl-link uppercase" onClick={() => seeFullToggle()}>
                        {see_full ? t('loan_hide_button') : t('loan_show_button') }
                    </a>
                </div>
            </div>
        </>
    );
}

export function HelpMyLoan(props) {
    const [show, setShow] = useState(false);
    const [is_sent, setIsSent] = useState(false);
    const { t, i18n } = useTranslation();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [error_resp, setError] = useState(null);
    const borrower_context_data = useContext(BorrowerContext);

    const handleHelpMyLoan = () => {

            Api.post('/help-my-loan/set', {"params":{"id":props.loan_id}})
                .then(
                    (result) => {
                        let response = result.data;

                        if(response.code === 0) {
                            setIsSent(true);

                            let loan = borrower_context_data.loan;
                            loan.stage = 12;
                            borrower_context_data.fillLoan(loan);

                        } else {
                            setError(GenerateError(response.errors[0].code));
                        }
                    },

                    (error) => {
                        setError(GenerateError());
                    }
                )

    }
    if (error_resp)
    {
        return <ErrorPage {...error_resp}/>
    }
    else {
        return (
            <div>
                <div className="flex-center-row-container">
                    <div className="top-margin">
                        <a className="nl-link" onClick={handleShow}>{t('help_my_loan_button')}</a>
                    </div>
                </div>
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    centered={true}
                    contentClassName="nl-modal"
                    size="lg"
                >
                    <div className="nl-modal-content">
                        <div className="nl-modal-header">

                        </div>
                        <div className="nl-modal-title">
                            {is_sent===false && t("help_my_loan_title")}
                            {is_sent===true && t("help_my_loan_title_sent")}
                        </div>

                        <div className="nl-modal-body">
                            {is_sent===false && t("help_my_loan_body")}

                        </div>
                        <div className="nl-modal-footer centered_text">
                            {is_sent===false && <button className="nl-button-white" onClick={handleClose}>{t('cancel')}</button>}
                            {is_sent===false && <button className="nl-button" onClick={handleHelpMyLoan}>{t('help_my_loan_submit')}</button>}
                            {is_sent===true && <button className="nl-button" onClick={handleClose}>{t('ok')}</button>}
                        </div>
                    </div>

                </Modal>
            </div>
        );
    }
}