import React from 'react';
import '../Styles/App.css';
import {useTranslation} from "react-i18next";

function Description() {
    const { t, i18n } = useTranslation();
    return(

            <div>
                <h1>Description</h1>
                <div>{t('description_text')}</div>
                <div>{t('description_text')}</div>
            </div>
    );

}

export default Description;