import React, {useContext, useEffect, useState} from 'react';
import '../Styles/App.css';
import {Trans, useTranslation} from "react-i18next";
import {Modal} from "react-bootstrap";
import Api from "./api";
import {BorrowerContext} from "./App";
import {ErrorPage, GenerateError} from "./Tools";

function ContactUs() {
    const { t, i18n } = useTranslation();
    const [is_email_required, setIsEmailRequired] = useState(false);
    const [is_sent, setIsSent] = useState(false);
    const borrower_context_data = useContext(BorrowerContext);
    const [error_resp, setError] = useState(null);

    useEffect(() => {
        if(borrower_context_data.loan.id === undefined) {
            setIsEmailRequired(true);
        }
    }, []);

    function closeModal() {
        setIsSent(false);
    }
    function submit(event) {
        event.preventDefault();
        console.log('SUBMITED');
        const data = new FormData(event.target);
        const value = Object.fromEntries(data.entries());
        if(borrower_context_data.loan.id !== undefined) {
            value.loan_id = borrower_context_data.loan.id;
        }
        console.log(value);

        Api.defaults.headers.post['Ninja-Lang'] = i18n.language;
        Api.post('/contact-us/set', {"params":value})
            .then(
                (result) => {
                    let response = result.data;
                    if (response.code === 0) {
                        setIsSent(true);
                    } else {
                        setError(GenerateError());
                    }

                },

                (error) => {
                    setError(GenerateError());
                }
            )
    }
    if (error_resp)
    {
        return <ErrorPage {...error_resp}/>
    } else {
        return (
            <>
                <Modal
                    show={is_sent}
                    onHide={closeModal}
                    backdrop="static"
                    keyboard={false}
                    centered={true}
                    contentClassName="nl-modal"
                >
                    <div className="nl-modal-content">

                        <div className="nl-modal-title">
                            {t("contact_us_sent_title")}
                        </div>

                        <div className="nl-modal-body">
                            {t("contact_us_sent_message")}
                        </div>
                        <div className="nl-modal-footer centered_text">
                            <button className="nl-button" onClick={closeModal}>{t('ok')}</button>
                        </div>
                    </div>

                </Modal>
                <div>
                    <h1>{t('contact_us_title')}</h1>
                    <div className="contact-us-text">
                        <Trans
                            i18nKey="contact_us_text"
                            values={{
                                support_email: process.env.REACT_APP_SUPPORT_EMAIL
                            }}
                            components={[]}
                        />
                    </div>
                    <div className="contact-us-text">{t('contact_us_pre_form')}</div>
                    <div className="top-margin">
                        <form onSubmit={(e) => submit(e)}>
                            <div>
                                <input name="preferred_email" required={is_email_required}
                                       placeholder={t('contact_us_preferred_email_placeholder')} type="email"/>
                            </div>
                            <div className="top-margin-50">
                                <textarea required={true} name="message" rows="6"
                                          placeholder={t('contact_us_message_placeholder')}></textarea>
                            </div>
                            <div className="top-margin-20 centered_text">
                                <button className="nl-button">{t('contact_us_send')}</button>
                            </div>
                        </form>
                    </div>
                    <div className="small-text top-margin-20 centered_text">
                        {t('contact_us_gdpr_statement')}
                    </div>
                </div>
            </>
        );
    }

}

export default ContactUs;