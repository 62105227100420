import React, { useState} from 'react';
import '../Styles/App.css';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";


function Footer() {
    const { t, i18n } = useTranslation();
    return (
        <div className="nl-footer flex-row-container flex-centered">
            <div className="flex-col"><a href={process.env.REACT_APP_ABOUT_US_PAGE} target="_blank">{t('footer_about')}</a></div>
            <div className="flex-col"><Link to="/faq">{t('footer_faq')}</Link></div>
            <div className="flex-col"><Link to="/contact-us">{t('footer_contact_us')}</Link></div>
        </div>
    );

}

export default Footer;