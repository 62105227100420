import React, {useContext, useState} from 'react';
import '../Styles/App.css';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {BorrowerContext} from "./App";

function HeaderTools() {
    const { t, i18n } = useTranslation();
    const borrower_context_data = useContext(BorrowerContext);
    let history = useHistory();

    return (
        <div className="header-tools">
            <div>
                <a onClick={() => history.goBack()} className="nl-header-tools-button">
                    &#8592; {t('back_button')}
                </a>
            </div>
            <div>
                {borrower_context_data.additional_tools_block}
            </div>
        </div>
    );

}

export default HeaderTools;

export function SkipButton(props) {
    const { t, i18n } = useTranslation();
    let history = useHistory();

    return (
        <a onClick={() => history.push(props.route)} className="nl-header-tools-button" >{t('skip_button')} &#8594;</a>
    )
}