import React, {useContext, useEffect, useState} from 'react';
import '../Styles/App.css';
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {BorrowerContext} from "./App";
import Api from "./api";
import {ErrorPage, GenerateError, NinjaLoader} from "./Tools";
import {DistributingResult} from "./Distributing";
import {Modal} from "react-bootstrap";

function DeclineLoan() {
    const { t, i18n } = useTranslation();
    let history = useHistory();
    const [error_resp, setError] = useState(null);
    const [decline_reasons_option, setDeclineReasonsOption] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [decline_reason, setDeclineReason] = useState(0);
    const [decline_comment, setDeclineComment] = useState('');


    const [is_declined, setIsDeclined] = useState(false);
    const borrower_context_data = useContext(BorrowerContext);
    useEffect(() => {
        borrower_context_data.setActiveStep(3);
        console.log('=============================');
        console.log(borrower_context_data);
        console.log('=============================');
    },[]);
    useEffect(() => {
        console.log(borrower_context_data.loan);
        if(borrower_context_data.loan.stage === 6){ // loan already on declined stage
            setIsDeclined(true);
            setIsLoading(false);
        } else {
            function getDeclineReasonList() {
                setIsLoading(true);
                Api.defaults.headers.post['Ninja-Lang'] = i18n.language;
                Api.post('/borrower-decline-reason/list', {})
                    .then(
                        (result) => {
                            let response = result.data;
                            if (response.code === 0) {
                                let reasons = response.data;
                                let reasons_option = reasons.map(function(reason){
                                    return <option key={reason.id} value={reason.id}>{reason.localized_title}</option>
                                })
                                setDeclineReasonsOption(reasons_option);

                            } else {
                                setError(GenerateError());
                            }
                            setIsLoading(false);

                        },

                        (error) => {
                            setIsLoading(false);
                            setError(GenerateError());
                        }
                    )
            }
            getDeclineReasonList();
        }


    }, [i18n.language]);

    function changeReason(event) {
        let val = event.target.value;
        console.log(val);
        setDeclineReason(val);
    }

    function changeComment(event) {
        let val = event.target.value;
        console.log(val);
        setDeclineComment(val);
    }


    function sendDeclineRequest() {
        setIsLoading(true);
        console.log(decline_reason);
        const request_body = {
            params: {
                id: borrower_context_data.loan.id,
                decline_reason: decline_reason,
                decline_comment: decline_comment
            }
        };
        Api.post('/loan/decline', request_body)
            .then(
                (result) => {
                    let response = result.data;
                    if(response.code === 0) {
                        setIsDeclined(true);
                    } else {
                        if(response.errors[0].code === -3010405) {
                            setIsDeclined(true);
                        } else {
                            setError(GenerateError());
                        }
                    }
                    setIsLoading(false);

                },

                (error) => {
                    setIsLoading(false);
                    setError(GenerateError());
                }
            )
    }



    if (error_resp)
    {
        return <ErrorPage {...error_resp}/>
    }
    else
    {
        if(isLoading) {
            return (<NinjaLoader />);
        } else {
            if(is_declined === true) {

                const result = <div>
                    <div className="distribution-title">{t('decline_loan_success_title')}</div>
                    <div className="distribution-text">{t('decline_loan_success_text')}</div>
                </div>;

                return (
                    <div>
                        <div><DistributingResult distribution_heading={result} point={2}/></div>
                    </div>
                );
            } else {
                return (
                    <div>

                        <div className="decline-title">
                            {t('decline_loan_title')}
                        </div>
                        <div className="decline-text">
                            {t('decline_loan_text')}
                        </div>
                        <div className="flex-row-container">
                            <select className="nl-select" onChange={changeReason} value={decline_reason}>
                                <option key="0" value="0">{t('decline_loan_select_placeholder')}</option>
                                {decline_reasons_option}
                            </select>
                        </div>
                        <div className="flex-row-container top-margin-50">
                            <textarea rows="10" onChange={changeComment}></textarea>

                        </div>
                        <div>
                            <div className="flex-center-row-container">
                                <div className="top-margin bottom-margin">
                                    <button className="nl-button" onClick={() => sendDeclineRequest()}>{t('decline_loan_button')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }

        }

    }

}

export default DeclineLoan;