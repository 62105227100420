import '../Styles/App.css';
import Welcome from './Welcome';
import Loan from "./Loan";
import AuthByHash from "./AuthByHash";
import Distributing from "./Distributing";
import Description from "./Description";
import ContactUs from "./ContactUs";
import Suggestions from "./Suggestions";
import LoanReview from "./LoanReview";
import HeaderTools from "./HeaderTools";
import ClearLoanData from "./ClearLoanData";
import BuyersList from "./BuyersList";
import {ReactComponent as NlLogo} from '../Images/Logos/NL.svg';
import {Faq} from "./Faq";
import React, { Suspense } from "react";
import Api from './api';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import {NinjaLoader,LanguageSelector} from "./Tools";
import DeclineLoan from "./DeclineLoan";
import Steps from "./Steps";
import Footer from "./Footer";

Api.interceptors.response.use(response => {
    console.log('axios interceptor response');
    console.log(response);
   /* if(response.data.code !== undefined){
        if(response.data.code === -96 || response.data.code === -97 || response.data.code === -98) {
     //       window.location.href = `/description`;
        }
    }*/
    return response;
}, error => {
    return Promise.reject(error);
});

export const BorrowerContext = React.createContext({
    token: '',
    borrower: {},
    loan: {},
    fillBorrowerInfo: () => {},
    fillLoan: () => {},
    base_lang: '',
    base_url: '',
    additional_tools_block: <></>
});
export const StepsContext = React.createContext({
    active_step: 0,
    setActiveStep: () => {}
});
class App extends React.Component {

    constructor(props) {
        super(props);
        this.fillBorrowerInfo = (borrower_data,loan_data,token) => {
            this.setState(state => ({
                borrower: borrower_data,
                loan: loan_data
            }));

            sessionStorage.setItem('borrower',JSON.stringify(borrower_data));
            sessionStorage.setItem('loan',JSON.stringify(loan_data));
            sessionStorage.setItem('token',token);

            Api.defaults.headers.post['Authorization'] = 'Bearer '+token;
            console.log('set_date_to_storage');
        };
        this.fillLoan = (loan_data) => {
            this.setState(state => ({
                loan: loan_data
            }));
            sessionStorage.setItem('loan',JSON.stringify(loan_data));
        }
        this.setActiveStep = (step) => {
            let new_step = step;
            if(isNaN(step)){
                new_step = 0;
            }
            if(step<0){
                new_step = 0;
            }
            if(step>3){
                new_step = 3;
            }
            this.setState(state => ({
                active_step: new_step
            }));
        }

        this.setAdditionalToolsBlock = (block) => {
            this.setState(state => ({
                additional_tools_block: block
            }));
        }

        this.state = {
            borrower: sessionStorage.getItem('borrower') ? JSON.parse(sessionStorage.getItem('borrower')) : {},
            loan: sessionStorage.getItem('loan') ? JSON.parse(sessionStorage.getItem('loan')) : {},
            fillBorrowerInfo: this.fillBorrowerInfo,
            fillLoan: this.fillLoan,
            base_lang: 'en',
            active_step: 1,
            setActiveStep: this.setActiveStep,
            isLoaded: false,
            additional_tools_block: <></>,
            setAdditionalToolsBlock: this.setAdditionalToolsBlock
        };

    }

    componentDidMount() {
        this.setState({isLoaded: false});
        console.log('++++++++++++++++++++++++++++++++++++++++++++++++++++');
        console.log(sessionStorage.getItem('token'));
        console.log('++++++++++++++++++++++++++++++++++++++++++++++++++++');
        if(sessionStorage.getItem('token') !== null) {
            Api.defaults.headers.post['Authorization'] = 'Bearer '+sessionStorage.getItem('token');
        }
        const getCsrfToken = async () => {
            //const { data } = await Api.post('/token');
           // Api.defaults.headers.post['X-CSRF-Token'] = data.data.token;
            Api.defaults.headers.post['Content-Type'] = 'application/json';
            this.setState({isLoaded: true});
        };  getCsrfToken();
    }

    render() {
        /* <nav>
            <ul>
                <li><Link to="/">Welcome</Link></li>
                <li><Link to="/auth/3def184ad8f4755ff269862ea77393dd">Auth with hash</Link></li>
                <li><Link to="/auth/3def184ad8f4755ff269862ea77393dd/decline">Auth and decline</Link></li>
                <li><Link to="/auth/c8ffe9a587b126f152">Auth with hash fail</Link></li>
                <li><Link to="/distributing">Distributing</Link></li>
                <li><Link to="/loan">Loan</Link></li>
                <li><Link to="/faq">FAQ</Link></li>
                <li><Link to="/decline">Decline</Link></li>
            </ul>
        </nav>*/
        return (

            <div className="nl-content">

                <BorrowerContext.Provider value={this.state}>
                    <Router>
                        <div className="nl-header">
                            <div className="nl-logo">
                                <NlLogo/>
                            </div>
                            <div>
                                <Suspense fallback={<select></select>}>
                                    <LanguageSelector />
                                </Suspense>
                            </div>

                        </div>
                        <div className="nl-body">
                            <Suspense fallback={<div></div>}>
                                <Steps/>
                            </Suspense>

                            {this.state.isLoaded &&
                            <div className="App">
                                <Suspense fallback={<NinjaLoader/>}>

                                    {(this.state.loan && this.state.loan.stage === 8) &&
                                    <Switch>
                                        <Route path="/auth/:auth_hash/:redirect_to">
                                            <AuthByHash/>
                                        </Route>
                                        <Route path="/auth/:auth_hash">
                                            <AuthByHash/>
                                        </Route>
                                        <Route path="/">
                                            <ClearLoanData/>
                                        </Route>
                                    </Switch>
                                    }
                                    {(!this.state.loan || this.state.loan.stage !== 8) &&
                                    <Switch>
                                        <Route path="/partners">
                                            <HeaderTools/>
                                            <BuyersList/>
                                        </Route>
                                        <Route path="/faq">
                                            <HeaderTools/>
                                            <Faq/>
                                        </Route>
                                        <Route path="/distributing">
                                            <HeaderTools/>
                                            <Distributing/>
                                        </Route>
                                        <Route path="/suggestions">
                                            <HeaderTools/>
                                            <Suggestions/>
                                        </Route>
                                        <Route path="/description">
                                            <HeaderTools/>
                                            <Description/>
                                        </Route>
                                        <Route path="/contact-us">
                                            <HeaderTools/>
                                            <ContactUs/>
                                        </Route>
                                        <Route path="/loan">
                                            <HeaderTools/>
                                            <Loan/>
                                        </Route>
                                        <Route path="/review">
                                            <HeaderTools/>
                                            <LoanReview/>
                                        </Route>
                                        <Route path="/auth/:auth_hash/:redirect_to">
                                            <AuthByHash/>
                                        </Route>
                                        <Route path="/auth/:auth_hash">
                                            <AuthByHash/>
                                        </Route>
                                        <Route path="/decline">
                                            <HeaderTools/>
                                            <DeclineLoan/>
                                        </Route>
                                        <Route path="/clear">
                                            <ClearLoanData/>
                                        </Route>
                                        <Route path="/">
                                            <Welcome/>
                                        </Route>
                                    </Switch>
                                    }
                                </Suspense>
                            </div>
                            }
                        </div>



                        <Suspense fallback={<div></div>}>
                            <Footer />
                        </Suspense>

                    </Router>
                </BorrowerContext.Provider>
            </div>

        );


    }
}

export default App;
