import React, { useState, useEffect } from 'react';
import '../Styles/App.css';

function LogoPick(props) {
    // TODO: Переделать, сейчас делаем хардкод ибо искать и реализовывать адекватный протекшен по передаче SVG тупо долго(война ж бля)

    const [logo, setLogo] = useState(null);
    const [class_name, setClassName] = useState('top-logo');
    useEffect(() => {
        console.log('lets get show some logo');
        console.log(props.organization_id);
        if(props.organization_id>0) {
            switch (props.organization_id) {
                case '23':
                    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!--------------FOUND');
                    setLogo(require('../Images/Logos/takto.svg').default);
                    break;
                default:
                    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!------NOOOOO--------FOUND');
                    //setLogo(null);
                    setLogo(require('../Images/Logos/takto.svg').default);
                    break;
            }
        }
        if(props.class_name !== undefined) {
            setClassName(props.class_name);
        }
    },[]);


    if(logo !== null){
        return (
            <div><img className={class_name} src={logo}/></div>
        );
    } else {
        return (<div></div>);
    }
}

export default LogoPick;

