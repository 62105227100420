import React, { useState, useEffect, useContext} from 'react';
import '../Styles/App.css';
import {useTranslation} from "react-i18next";
import {BorrowerContext} from "./App";
import CheckIcon from '@mui/icons-material/Check';

function Steps() {
    const { t, i18n } = useTranslation();
    const borrower_context_data = useContext(BorrowerContext)

    const default_step_sub_text_classes = [
        "step_sub_text text_left",
        "step_sub_text",
        "step_sub_text text_right"
    ];

    const [step_sub_classes, setStepSubClasses] = useState(default_step_sub_text_classes);
    const [current_step_row, setCurrentStepRow] = useState(<div></div>);
    const active_class = 'active';

    const step_rows = {
        0 : <div className="step-container">
            <div className="step-point">
            </div>
            <div className="step-line"></div>
        </div>,
        1: <div className="step-container">

            <div className="step-line active"></div>
            <div className="step-point">
            </div>
            <div className="step-line"></div>

        </div>,
        2: <div className="step-container">
            <div className="step-line active"></div>
            <div className="step-point">
            </div>
        </div>
    }

    useEffect(() => {
        console.log('!!!!!--------------------------------ACTIVE_STEP'+borrower_context_data.active_step);

        if(step_rows[borrower_context_data.active_step]) {
            setCurrentStepRow(step_rows[borrower_context_data.active_step]);
        } else {
            setCurrentStepRow(<div></div>);
        }
        let new_step_sub_classes = [];
        for(let i=0; i<3; i++){
            let step_sub_row = default_step_sub_text_classes[i];
            if(i === borrower_context_data.active_step){
                step_sub_row = step_sub_row+' '+active_class;
            }
            new_step_sub_classes.push(step_sub_row);
        }
        setStepSubClasses(new_step_sub_classes);
    }, [i18n.language,borrower_context_data.active_step]);

    return (
        <div>

            {current_step_row}

            <div className="step-container">
                <div className={step_sub_classes[0]}>
                    {t('steps_01')}
                </div>
                <div className={step_sub_classes[1]}>
                    {t('steps_02')}
                </div>
                <div className={step_sub_classes[2]}>
                    {t('steps_03')}
                </div>

            </div>
        </div>
    );

}

export default Steps;