import React, { useState, useEffect, useContext } from 'react';
import '../Styles/App.css';
import {useTranslation} from "react-i18next";
import Api from "./api";
import {ErrorPage, GenerateError, NinjaLoader} from "./Tools";
import LogoPick from "./LogoPick";

function BuyersList() {
    const [error_resp, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [buyers, setBuyers] = useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {

        function fetchBuyersData() {

            Api.post('/consent/buyers-list',{})
                .then(
                    (response) => {
                        let result = response.data;
                        if (result.code !== undefined && result.code === 0) {
                            let buyers_rows = Object.keys(result.data).map((key) => {

                                return <div className="buyers-list-col" key={key}>
                                    <a className="partner-link" target="_blank" href={result.data[key].web_page_url}>
                                        <div className="buyers-list-row">
                                            <div>
                                                <LogoPick organization_id={result.data[key].id} class_name=""/>
                                            </div>
                                            <div className="buyers-list-name">
                                                {result.data[key].name}
                                            </div>
                                        </div>
                                    </a>
                                </div>

                            });

                            setBuyers(buyers_rows);

                        } else {
                            setError(GenerateError(response.errors[0].code));

                        }
                        setIsLoaded(true);
                    },

                    (error) => {
                        setError(GenerateError());
                        setIsLoaded(true);
                    }
                )
        }
        fetchBuyersData();
    }, []);

    if(isLoaded === false) {
        return <NinjaLoader />
    } else {
        if (error_resp) {
            return <ErrorPage {...error_resp}/>
        } else {
            return (
                <div>
                    <h1>{t('buyers_list_title')}</h1>
                    <div className="buyers-list">
                        {buyers}
                    </div>
                </div>
            );
        }
    }

}
export default BuyersList;